import React from 'react'
import '../styles.css'

function Navigation() {
    return (
        <div role="navigation" className="div_navContainer" >

        </div>
    )
}

export default Navigation
