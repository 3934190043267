import React from 'react'

function Complementary(props) {
    return (
            <div role="complementary" className="div_complementaryContainer" style={{width: '350px'}}>
                
            </div>
    )
}

export default Complementary